/* global VideoPlayer */
import { config } from './config';

import Swiper, { Navigation, Pagination, Lazy, Autoplay, Scrollbar } from 'swiper';
import { isXPM } from '../helpers/isXPM';
import { VideoPopup } from '../common/videoPopup';

/**
 * Carousel is a generic carousel component with common carousel methods.
 */
export class Carousel {
  /**
   * Toggle autoplay functionality depending of presence of 'autoplay' attribute on container
   * @param {Node} $carousel Carousel container dom node
   * @returns {Boolean}
   */
  toggleAutoplay($carousel) {
    /**
     * Disable autoplay when in XPM
     */
    if (!$carousel.hasAttribute('autoplay') || isXPM() ) {
      return false;
    }

    const interval = $carousel.getAttribute('autoplay-interval');

    return interval ? { delay: interval } : config.autoplay;
  }

  /**
   * Check if carousel has only one slide
   * @param {Node} carousel Carousel container dom node
   * @returns {Boolean}
   */
  hasSingleSlide(carousel) {
    return carousel.getElementsByClassName('swiper-slide').length === 1;
  }

  /**
   * Check if carousel has autoplay attribute
   * @param {Node} carouselID
   * @returns {boolean}
   */
  hasAutoplayAttr(carouselID) {
    return $(`#${carouselID}`).attr('autoplay');
  }

  /**
   * Extends common config with passed config
   * @param {Object} extendConfig - Passed override of the common config
   * @returns {Object} Extended config
   */
  extendConfig(extendConfig) {
    const extendedConfig = $.extend({}, config, extendConfig);

    if (extendedConfig.pagination) {
      extendedConfig.modules.push(Pagination);
    }

    if (extendedConfig.navigation) {
      extendedConfig.modules.push(Navigation);
    }

    if (extendedConfig.lazy) {
      extendedConfig.modules.push(Lazy);
    }

    if (extendConfig.autoplay) {
      extendedConfig.modules.push(Autoplay);
    }

    if (extendConfig.scrollbar) {
      extendedConfig.modules.push(Scrollbar);
    }

    return extendedConfig;
  }

  /**
   * Inits video popups for duplicate slides
   * @param {Object} carousel
   * @param {Node} $carousel
   */
  supportDuplicateSlideVideos(carousel, $carousel) {
    carousel.on('slideChangeTransitionEnd', () => {
      if ($carousel.find('.swiper-slide-duplicate.swiper-slide-active .js-video-popup').length) {
        new VideoPopup();
      }
    });
  }

  /**
   * Inits the carousels in child classes
   * @param {Object} carouselClass - child class
   * @param {String} selector - css selector of carousel container
   */
  init(carouselClass, selector) {
    $.each($(selector), (index, carouselNode) => {
      const $carousel = $(carouselNode);
      const id = `${selector.replace('.js-', '')}-${index}`;
      $carousel.attr('id', id);
      const carousel = new Swiper(`#${id}`, carouselClass.generateConfig(carouselNode));
      // Added carousel to child class map for future use
      carouselClass.carousel.set(id, carousel);
      carousel.init();
      /** If video get supported on promobox carousel remove selector check */
      if (selector === '.js-intro-carousel' && $carousel.find('.js-video-popup').length) {
        this.supportDuplicateSlideVideos(carousel, $carousel);
      }

      /**
       * Carousel play-pause button.
       */
      if (this.hasSingleSlide(carouselNode) || !this.hasAutoplayAttr(id)) {
        $carousel.find('.js-play-pause-btn').css('display', 'none');
      } else {
        $carousel.find('.js-play-pause-btn').on('click', () => {
          if($carousel.find('.js-play-pause-text').length > 0) {
            const $button = $carousel.find('.js-play-pause-text');
            let currentState = $button.text();
            let nextState = $button.attr('data-state-toggle');

            $button.text(nextState).attr('data-state-toggle', currentState);
          }

          carousel.autoplay.running ? carousel.autoplay.stop() : carousel.autoplay.start();
          $carousel.find('.svg-icon.play-icon').toggleClass('play-icon--is-paused');
        });
      }

      /**
       * Used for the box carousel component.
       * Toggles the play-pause state of a carousel.
       */
      if (this.hasSingleSlide(carouselNode) || !this.hasAutoplayAttr(id)) {
        $carousel.find('.js-play-pause-box-btn').hide();
      } else {
        $carousel.find('.js-play-pause-box-btn').on('click', () => {
          const $button = $carousel.find('.js-play-pause-text');
          let currentState = $button.text();
          let nextState = $button.attr('data-state-toggle');

          carousel.autoplay.running ? carousel.autoplay.stop() : carousel.autoplay.start();
          $button.text(nextState).attr('data-state-toggle', currentState);
          $carousel.find('.svg-icon.play-icon').toggleClass('play-icon--is-paused');
        });
      }

      carousel.on('slideChange', () => {
        for (const slide in carousel.slides) {
          if (typeof carousel.slides[slide] === 'object') {
            const $slide = $(carousel.slides[slide]);
            const $player = $slide.find('.inline-video-container .jwplayer');
            if ($player.length) {
              const playerId = $player.attr('id');
              carousel.activeIndex === Number(slide) ?
                window.jwplayer(playerId).play() : window.jwplayer(playerId).pause();
            }
          }
        }

        /**
         * Implementation of setTimeout() to make code execution after manuel slider 
         * change (swipe, slider dots click or next-previous arrow click). 
         */
        setTimeout(() => {
          if (!carousel.autoplay?.running) {
            $carousel.find('.svg-icon.play-icon').removeClass('play-icon--is-paused');
          }
        }, 0);
      });

      $('.js-video-playback').on('click', (event) => {
        const $videoContainer = $(event.target).closest('.video-container');
        const $player = $($videoContainer).find('.jwplayer');

        if ($player.length) {
          const playerId = $player.attr('id');
          window.jwplayer(playerId).getState() === 'playing' ?
             window.jwplayer(playerId).pause() : window.jwplayer(playerId).play();

          const $button = $($videoContainer).find('.js-video-playback-text');
          let currentState = $button.text();
          let nextState = $button.attr('data-state-toggle');

          $button.text(nextState).attr('data-state-toggle', currentState);
          $carousel.find('.video-playback-svg').toggleClass('video-playback-svg--is-paused');
        }
      });

      carousel.on('init', () => {
        $.each($carousel.find('.video-player-init-disabled'), (carouselIndex, player) => new VideoPlayer(player));
      });
    });
  }
}